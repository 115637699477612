import React, { useState } from "react";
import Logo from "../assets/img/logo.jpeg";
import { FaFacebook } from "react-icons/fa";
import { FaXTwitter, FaInstagram } from "react-icons/fa6";
import { Link } from "react-scroll";
import { GiHamburgerMenu } from "react-icons/gi";
import { ImCross } from "react-icons/im";

interface NavObject {
  name?: string;
}

const Header = () => {
  const [navBar, setNavBar] = useState(true);
  const nav: NavObject[] = [
    {
      name: "Home",
    },
    {
      name: "About Us",
    },
    {
      name: "Products",
    },
    {
      name: "Services",
    },

    {
      name: "Contact Us",
    },
  ];
  const closeNav = () => {
    setNavBar(false);
  };

  return (
    <div className='fixed h-40 inset-0 z-50'>
      <div className='h-10 flex justify-center md:justify-between items-center bg-[#020618] text-sm  w-full  px-10 text-white '>
        <h2 className='font-thin hidden md:block '>
          THE ORIGINAL TASTE OF INDIAN SPICES
        </h2>
        <div className='flex items-center gap-6'>
          <span>
            <FaFacebook size={22} />
          </span>
          <span>
            <FaXTwitter size={22} />
          </span>
          <span>
            <FaInstagram size={22} />
          </span>
        </div>
      </div>
      <div className='flex justify-between items-center md:px-10  text-[#020618] bg-white font-serif border-b-[1px]'>
        <div className='w-[8rem]'>
          <img src={Logo} alt='logo' className='md:w-full w-20 ' />
        </div>
        <div
          className='flex justify-between pr-5 md:hidden'
          onClick={() => setNavBar(!navBar)}
        >
          {navBar ? <GiHamburgerMenu size={30} /> : <ImCross size={21} />}
        </div>
        <div className='hidden md:block'>
          <ul className=' w-[40vw] flex justify-between px-10  '>
            {nav.map(({ name }: NavObject) => (
              <li className='cursor-pointer hover:border-b-[3px] font-[600] rounded border-yellow-500'>
                <Link
                  spy={true}
                  smooth={true}
                  offset={-100}
                  duration={500}
                  to={name || "/"}
                >
                  {name}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className='md:hidden'>
        {!navBar && (
          <div className='bg-white w-full h-[14rem]'>
            {nav.map(({ name }: NavObject) => (
              <li className='cursor-pointer rounded  py-2 px-4 text-[13px] flex flex-col gap-2'>
                <Link
                  onClick={() => setNavBar(!navBar)}
                  spy={true}
                  smooth={true}
                  offset={-100}
                  duration={500}
                  to={name || "/"}
                >
                  {name}
                </Link>
              </li>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
