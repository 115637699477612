import React from "react";
import { FaXTwitter } from "react-icons/fa6";
import Logo from "../assets/img/logo.jpeg";
import { Link } from "react-scroll";

const Footer = () => {
  return (
    <footer className='m-0 bg-[#221141] font-[Poppins] text-[d6dfed]  box-border'>
      <footer className='bg-white dark:bg-gray-900'>
        <div className='mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8'>
          <div className='md:flex md:justify-between'>
            <div className='mb-6 md:mb-0'>
              <a href='/' className='flex items-center'>
                <img src={Logo} className='h-20 me-3' alt='FlowBite Logo' />
                <span className='self-center text-2xl font-semibold whitespace-nowrap dark:text-white'>
                  HRM Spices
                </span>
              </a>
              <div className="w-[14rem]">Plot No. 8, Khewra Bahalgarh Road, Sonipat,Haryana-131021</div>
              <span>+91 8070605111</span>
            </div>

            <div className='grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-3'>
              <div>
                <h2 className='mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white'>
                  Resources
                </h2>
                <ul className='text-gray-500 dark:text-gray-400 font-medium'>
                  <li className='mb-4 cursor-pointer'>
                    <Link
                      spy={true}
                      smooth={true}
                      offset={-100}
                      duration={500}
                      to={"Products" || "/"}
                    >
                      Products
                    </Link>
                  </li>
                  <li className='cursor-pointer'>
                    <Link
                      spy={true}
                      smooth={true}
                      offset={-100}
                      duration={500}
                      to={"Contact Us" || "/"}
                    >
                      Contact Us
                    </Link>
                  </li>
                </ul>
              </div>
              <div>
                <h2 className='mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white'>
                  Follow us
                </h2>
                <ul className='text-gray-500 dark:text-gray-400 font-medium'>
                  <li className='mb-4'>
                    <a
                      href='https://github.com/themesberg/flowbite'
                      className='hover:underline '
                    >
                      Instagram
                    </a>
                  </li>
                  <li>
                    <a
                      href='https://discord.gg/4eeurUVvTy'
                      className='hover:underline'
                    >
                      Facebook
                    </a>
                  </li>
                </ul>
              </div>
              <div>
                <h2 className='mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white'>
                  Legal
                </h2>
                <ul className='text-gray-500 dark:text-gray-400 font-medium'>
                  <li className='mb-4'>
                    <a href='#' className='hover:underline'>
                      Privacy Policy
                    </a>
                  </li>
                  <li>
                    <a href='#' className='hover:underline'>
                      Terms &amp; Conditions
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <hr className='my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8' />
        </div>
      </footer>

      <div className='bg-gray-800 text-white font-sans text-[10px] py-2 md:text-sm px-4 md:px-12 flex justify-between items-center'>
        <h2>Copyright @ 2024 HRM Spices</h2>
        <h2 className='cursor-pointer'>
          Website Designed By |{" "}
          <a
            className='hover:underline'
            target='_blank'
            href='https://theopensoft.com'
          >
            The Open Soft
          </a>
        </h2>
      </div>
    </footer>
  );
};

export default Footer;
