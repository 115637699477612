import React from "react";
import { TypewriterEffectSmooth } from "../../ui/typewriter-effect";
import { HoverEffect } from "../../ui/card-hover-efffect";

const Service = () => {
  const words = [
    {
      text: "We",
    },
    {
      text: "have",
    },
    {
      text: "provided",
    },
    {
      text: "these",
    },
    {
      text: "Services.",
      className: "text-blue-500 dark:text-blue-500",
    },
  ];
  const projects = [
    {
      title: "Dealers",
      description:
        "We provide our sales services in dealers for whole sale buisness.",
      link: "/#contact",
    },
    {
      title: "Shops",
      description:
        "We provide our product for sales to direct in shops with excellent offers.",
      link: "/#contact",
    },
    {
      title: "Kitchen",
      description: "We also provide raw material for your kitchen.",
      link: "/#contact",
    },
  ];
  return (
    <div className='p-10' id="Services">
      <div className='text-center text-2xl md:text-4xl font-[800] text-gray-700 flex justify-center gap-2 items-center'>
        <span className='border-4 w-[3rem] md:w-[6rem] rounded-full border-yellow-500'></span>
        <h2>Services</h2>
        <span className='border-4 w-[3rem] md:w-[6rem] rounded-full border-yellow-500'></span>
      </div>
      <div className='flex items-center justify-center'>
        <TypewriterEffectSmooth words={words} className='text-[16px]' />
      </div>
      <HoverEffect items={projects} />
    </div>
  );
};

export default Service;
