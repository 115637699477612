"use client";
import React from "react";
import { Label } from "../../ui/label";
import { Input } from "../../ui/input";
import { cn } from "../../../utils/cn";
import { AuroraBackground } from "../../ui/aurora-background";
import { Button } from "../../ui/moving-border";

export default function ContactUs() {
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log("Form submitted");
  };
  return (
    <div className='bg-gray-100' id='Contact Us'>
      <div className='text-center text-2xl md:text-4xl font-[800] text-gray-700 flex justify-center gap-2 items-center py-6'>
        <span className='border-4 w-[3rem] md:w-[6rem] rounded-full border-yellow-500'></span>
        <h2>Contact Us</h2>
        <span className='border-4 w-[3rem] md:w-[6rem]  rounded-full border-yellow-500'></span>
      </div>
      <div className='flex  justify-between'>
        <div className='container my-12 md:my-24 mx-auto md:px-6'>
          <section className='md:mb-32'>
            <div className='flex flex-wrap'>
              <div className='mb-12 w-full shrink-0 grow-0 basis-auto px-2 md:px-3 lg:mb-0 lg:w-5/12 lg:px-6'>
                <form>
                  <div className='relative mb-6' data-te-input-wrapper-init>
                    <input
                      type='text'
                      className='peer block min-h-[auto] w-full rounded  bg-transparent py-[0.32rem] px-3 leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 peer-focus:text-primary data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-neutral-200 dark:placeholder:text-neutral-200 dark:peer-focus:text-primary [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0 border-[1px]'
                      id='exampleInput90'
                      placeholder='Name'
                    />
                    <label
                      className='pointer-events-none absolute top-0 left-3 mb-0 max-w-[90%] origin-[0_0] truncate pt-[0.37rem] leading-[1.6] text-neutral-500 transition-all duration-200 ease-out peer-focus:-translate-y-[0.9rem] peer-focus:scale-[0.8] peer-focus:text-primary peer-data-[te-input-state-active]:-translate-y-[0.9rem] peer-data-[te-input-state-active]:scale-[0.8] motion-reduce:transition-none dark:text-neutral-200 dark:peer-focus:text-primary'
                      htmlFor='exampleInput90'
                    >
                      Name
                    </label>
                  </div>
                  <div className='relative mb-6' data-te-input-wrapper-init>
                    <input
                      type='email'
                      className='peer block min-h-[auto] w-full rounded border-[1px] bg-transparent py-[0.32rem] px-3 leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 peer-focus:text-primary data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-neutral-200 dark:placeholder:text-neutral-200 dark:peer-focus:text-primary [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0'
                      id='exampleInput91'
                      placeholder='Email address'
                    />
                    <label
                      className='pointer-events-none absolute top-0 left-3 mb-0 max-w-[90%] origin-[0_0] truncate pt-[0.37rem] leading-[1.6] text-neutral-500 transition-all duration-200 ease-out peer-focus:-translate-y-[0.9rem] peer-focus:scale-[0.8] peer-focus:text-primary peer-data-[te-input-state-active]:-translate-y-[0.9rem] peer-data-[te-input-state-active]:scale-[0.8] motion-reduce:transition-none dark:text-neutral-200 dark:peer-focus:text-primary'
                      htmlFor='exampleInput91'
                    >
                      Email
                    </label>
                  </div>
                  <div className='relative mb-6' data-te-input-wrapper-init>
                    <input
                      type='email'
                      className='peer block min-h-[auto] w-full rounded border-[1px] bg-transparent py-[0.32rem] px-3 leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 peer-focus:text-primary data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-neutral-200 dark:placeholder:text-neutral-200 dark:peer-focus:text-primary [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0'
                      id='exampleInput91'
                      placeholder='Email address'
                    />
                    <label
                      className='pointer-events-none absolute top-0 left-3 mb-0 max-w-[90%] origin-[0_0] truncate pt-[0.37rem] leading-[1.6] text-neutral-500 transition-all duration-200 ease-out peer-focus:-translate-y-[0.9rem] peer-focus:scale-[0.8] peer-focus:text-primary peer-data-[te-input-state-active]:-translate-y-[0.9rem] peer-data-[te-input-state-active]:scale-[0.8] motion-reduce:transition-none dark:text-neutral-200 dark:peer-focus:text-primary'
                      htmlFor='exampleInput91'
                    >
                      Address
                    </label>
                  </div>
                  <div className='relative mb-6' data-te-input-wrapper-init>
                    <textarea
                      className='peer block min-h-[auto] w-full rounded border-[1px] bg-transparent py-[0.32rem] px-3 leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-neutral-200 dark:placeholder:text-neutral-200 [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0'
                      id='exampleFormControlTextarea1'
                      rows={7}
                      placeholder='Your message'
                    ></textarea>
                    <label
                      htmlFor='exampleFormControlTextarea1'
                      className='pointer-events-none absolute top-0 left-3 mb-0 max-w-[90%] origin-[0_0] truncate pt-[0.37rem] leading-[1.6] text-neutral-500 transition-all duration-200 ease-out peer-focus:-translate-y-[0.9rem] peer-focus:scale-[0.8] peer-focus:text-primary peer-data-[te-input-state-active]:-translate-y-[0.9rem] peer-data-[te-input-state-active]:scale-[0.8] motion-reduce:transition-none dark:text-neutral-200 dark:peer-focus:text-primary'
                    >
                      Message
                    </label>
                  </div>

                  <Button
                    borderRadius='1.75rem'
                    className='bg-white dark:bg-slate-900 font-[800] text-lg text-[#eab305] dark:text-white border-neutral-200 dark:border-slate-800 cursor-pointer hover:bg-green-500 hover:text-white'
                  >
                    Submit
                  </Button>
                </form>
              </div>
              <div className='w-full shrink-0 grow-0 basis-auto lg:w-7/12'>
                <div className='flex flex-wrap'>
                  <div className='mb-12 w-full shrink-0 grow-0 basis-auto md:w-6/12 md:px-3 lg:px-6'>
                    <div className='flex items-start'>
                      <div className='shrink-0'>
                        <div className='inline-block rounded-md bg-primary-100 p-4 text-primary'>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            fill='none'
                            viewBox='0 0 24 24'
                            stroke-width='2'
                            stroke='currentColor'
                            className='h-6 w-6'
                          >
                            <path
                              stroke-linecap='round'
                              stroke-linejoin='round'
                              d='M14.25 9.75v-4.5m0 4.5h4.5m-4.5 0l6-6m-3 18c-8.284 0-15-6.716-15-15V4.5A2.25 2.25 0 014.5 2.25h1.372c.516 0 .966.351 1.091.852l1.106 4.423c.11.44-.054.902-.417 1.173l-1.293.97a1.062 1.062 0 00-.38 1.21 12.035 12.035 0 007.143 7.143c.441.162.928-.004 1.21-.38l.97-1.293a1.125 1.125 0 011.173-.417l4.423 1.106c.5.125.852.575.852 1.091V19.5a2.25 2.25 0 01-2.25 2.25h-2.25z'
                            />
                          </svg>
                        </div>
                      </div>
                      <div className='ml-6 grow'>
                        <p className='mb-2 font-bold dark:text-white'>
                          Technical support
                        </p>
                        <p className='text-neutral-500 dark:text-neutral-200'>
                          theopensoftpartners@gmail.com
                        </p>
                        <a
                          href='https://theopensoft.com'
                          className='text-neutral-500 dark:text-neutral-200 hover:underline hover:text-red-500'
                        >
                          The Open Soft
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className='mb-12 w-full shrink-0 grow-0 basis-auto md:w-6/12 md:px-3 lg:px-6'>
                    <div className='flex items-start'>
                      <div className='shrink-0'>
                        <div className='inline-block rounded-md bg-primary-100 p-4 text-primary'>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            fill='none'
                            viewBox='0 0 24 24'
                            stroke-width='2'
                            stroke='currentColor'
                            className='h-6 w-6'
                          >
                            <path
                              stroke-linecap='round'
                              stroke-linejoin='round'
                              d='M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z'
                            />
                          </svg>
                        </div>
                      </div>
                      <div className='ml-6 grow'>
                        <p className='mb-2 font-bold dark:text-white'>
                          Sales Inquiry
                        </p>
                        <p className='text-neutral-500 dark:text-neutral-200'>
                          info.hrmfoods@gmail.com
                        </p>
                        <p className='text-neutral-500 dark:text-neutral-200'>
                          +91-8070605111
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className='mb-12 w-full shrink-0 grow-0 basis-auto md:w-6/12 md:px-3 lg:px-6'>
                    <div className='align-start flex'>
                      <div className='shrink-0'>
                        <div className='inline-block rounded-md bg-primary-100 p-4 text-primary'>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            fill='none'
                            viewBox='0 0 24 24'
                            stroke-width='2'
                            stroke='currentColor'
                            className='h-6 w-6'
                          >
                            <path
                              stroke-linecap='round'
                              stroke-linejoin='round'
                              d='M12 7.5h1.5m-1.5 3h1.5m-7.5 3h7.5m-7.5 3h7.5m3-9h3.375c.621 0 1.125.504 1.125 1.125V18a2.25 2.25 0 01-2.25 2.25M16.5 7.5V18a2.25 2.25 0 002.25 2.25M16.5 7.5V4.875c0-.621-.504-1.125-1.125-1.125H4.125C3.504 3.75 3 4.254 3 4.875V18a2.25 2.25 0 002.25 2.25h13.5M6 7.5h3v3H6v-3z'
                            />
                          </svg>
                        </div>
                      </div>
                      <div className='ml-6 grow'>
                        <p className='mb-2 font-bold dark:text-white'>
                          Dealer Inquiry
                        </p>
                        <p className='text-neutral-500 dark:text-neutral-200'>
                          info.hrmfoods@gmail.com
                        </p>
                        <p className='text-neutral-500 dark:text-neutral-200'>
                          +91-8070605111
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className='mb-12 w-full shrink-0 grow-0 basis-auto md:w-6/12 md:px-3 lg:px-6'>
                    <div className='align-start flex'>
                      <div className='shrink-0'>
                        <div className='inline-block rounded-md bg-primary-100 p-4 text-primary'>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            fill='none'
                            viewBox='0 0 24 24'
                            stroke-width='2'
                            stroke='currentColor'
                            className='h-6 w-6'
                          >
                            <path
                              stroke-linecap='round'
                              stroke-linejoin='round'
                              d='M12 7.5h1.5m-1.5 3h1.5m-7.5 3h7.5m-7.5 3h7.5m3-9h3.375c.621 0 1.125.504 1.125 1.125V18a2.25 2.25 0 01-2.25 2.25M16.5 7.5V18a2.25 2.25 0 002.25 2.25M16.5 7.5V4.875c0-.621-.504-1.125-1.125-1.125H4.125C3.504 3.75 3 4.254 3 4.875V18a2.25 2.25 0 002.25 2.25h13.5M6 7.5h3v3H6v-3z'
                            />
                          </svg>
                        </div>
                      </div>
                      <div className='ml-6 grow'>
                        <p className='mb-2 font-bold dark:text-white'>
                          Address
                        </p>
                        <p>
                          <div className='w-[14rem] text-neutral-500 dark:text-neutral-200'>
                            Plot No. 8, Khewra Bahalgarh Road,
                            Sonipat,Haryana-131021
                          </div>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

const BottomGradient = () => {
  return (
    <>
      <span className='group-hover/btn:opacity-100 block transition duration-500 opacity-0 absolute h-px w-full -bottom-px inset-x-0 bg-gradient-to-r from-transparent via-cyan-500 to-transparent' />
      <span className='group-hover/btn:opacity-100 blur-sm block transition duration-500 opacity-0 absolute h-px w-1/2 mx-auto -bottom-px inset-x-10 bg-gradient-to-r from-transparent via-indigo-500 to-transparent' />
    </>
  );
};

const LabelInputContainer = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <div className={cn("flex flex-col space-y-2 w-full", className)}>
      {children}
    </div>
  );
};
