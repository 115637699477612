import React from "react";
import { WavyBackground } from "../../ui/wavy-background";
import { InfiniteMovingCards } from "../../ui/infinite-moving-cards";

const Testimonial = () => {
  const testimonials = [
    {
      quote:
        "It was the best of times, it was the worst of times, it was the age of wisdom, it was the age of foolishness, it was the epoch of belief, it was the epoch of incredulity, it was the season of Light, it was the season of Darkness, it was the spring of hope, it was the winter of despair.",
      name: "Charles Dickens",
      title: "A Tale of Two Cities",
    },
    {
      quote:
        "To be, or not to be, that is the question: Whether 'tis nobler in the mind to suffer The slings and arrows of outrageous fortune, Or to take Arms against a Sea of troubles, And by opposing end them: to die, to sleep.",
      name: "William Shakespeare",
      title: "Hamlet",
    },
    {
      quote: "All that we see or seem is but a dream within a dream.",
      name: "Edgar Allan Poe",
      title: "A Dream Within a Dream",
    },
    {
      quote:
        "It is a truth universally acknowledged, that a single man in possession of a good fortune, must be in want of a wife.",
      name: "Jane Austen",
      title: "Pride and Prejudice",
    },
    {
      quote:
        "Call me Ishmael. Some years ago—never mind how long precisely—having little or no money in my purse, and nothing particular to interest me on shore, I thought I would sail about a little and see the watery part of the world.",
      name: "Herman Melville",
      title: "Moby-Dick",
    },
  ];
  return (
    <div className=' m-8 p-0'>
      <div className='text-center text-2xl md:text-4xl font-[800] text-gray-700 flex justify-center gap-2 items-center'>
        <span className='border-4 w-[3rem] md:w-[6rem] rounded-full border-yellow-500'></span>
        <h2>Our Customer</h2>
        <span className='border-4 w-[3rem] md:w-[6rem] rounded-full border-yellow-500'></span>
      </div>
      <div className=' rounded-md flex flex-col antialiased bg-white dark:bg-black dark:bg-grid-white/[0.05] items-center py-16 relative overflow-hidden'>
        <InfiniteMovingCards
          items={testimonials}
          direction='right'
          speed='normal'
        />
      </div>
    </div>
  );
};

export default Testimonial;
