import React from "react";
import { BackgroundBeams } from "../../ui/background-beams";
import { Button } from "../../ui/moving-border";

const JoinBuisness = () => {
  return (
    <div>
      <div className='h-[40rem] w-full rounded-md bg-neutral-950 relative flex flex-col items-center justify-center antialiased'>
        <div className='max-w-2xl mx-auto p-4'>
          <h1 className='relative z-10 text-lg md:text-7xl  bg-clip-text text-transparent bg-gradient-to-b from-neutral-200 to-neutral-600  text-center font-sans font-bold'>
            Join Our Buisness
          </h1>
          <p></p>
          <p className='text-neutral-500 max-w-lg mx-auto my-2 text-sm text-center relative z-10'>
            Welcome to HRM, the best Spices service in India . We provide
            packed, unpacked, and holesale spices for your business and your
            kitchen. Whether you&apos;re sending order confirmations, Follow us.
          </p>
          <input
            type='text'
            placeholder='hi@example.com'
            className='rounded-lg border border-neutral-800 focus:ring-2 focus:ring-teal-500  w-full relative z-10 mt-4 p-2  bg-neutral-950 placeholder:text-neutral-700'
          />
          <div className='relative z-10 mt-4 p-2 flex justify-center'>
            <Button className=''>Subscribe</Button>
          </div>
        </div>
        <BackgroundBeams />
      </div>
    </div>
  );
};

export default JoinBuisness;
