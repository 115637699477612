import React from "react";
import SPICE1 from "../../assets/img/masala1.avif";
import SPICE2 from "../../assets/img/masala2.avif";
import SPICE3 from "../../assets/img/masala3.avif";
import { AuroraBackground } from "../../ui/aurora-background";
import { TextGenerateEffect } from "../../ui/text-generate-effect";
const About = () => {
  const words =
    "Welcome to HRM Spices have a long and ancient history, especially in India, where they are a part of life and heritage. In every home & in every province across the country, different spices and blends are used to create different and distinctive tastes in dishes. Several decades ago, housewives used to grind their spices manually at home and make their own blends for use in their cooking. To make this process easier for the housewife,  (HRM) visualised the concept of ready-to-use ground spices.";
  return (
    <AuroraBackground className='p-10' id='About Us'>
      <div className='text-center text-2xl md:text-4xl font-[800] text-gray-700 flex justify-center gap-2 items-center'>
        <span className='border-4 w-[3rem] md:w-[6rem] rounded-full border-yellow-500'></span>
        <h2>About Us</h2>
        <span className='border-4 w-[3rem] md:w-[6rem] rounded-full border-yellow-500'></span>
      </div>
      <section className='md:p-16 flex flex-col gap-4'>
        <div className='px-6 '>
          <TextGenerateEffect
            words={words}
            className='text-[17px] font-serif font-[700] leading-3 text-justify'
          />
        </div>
        <div className='flex flex-col md:flex-row justify-between border-[1px] px-4 gap-4 md:gap- md:px-16 border-green-500 py-4 items-center rounded-3xl'>
          <p className='text-justify md:w-[70%]'>
            The powdered spices are again tested atthe fully equipped quality
            control laboratories,to ensure the best quality standards. They
            arethen packed mechanically into packs of variousproportions without
            any human touch. The HRMmarketing and distribution team is always on
            themove to ensure timely delivery of its productsto the consumers.
            Further, well equipped research and development laboratories work
            incessantly todevelop new spice blends of spices. Mahashay Dharampal
            Ji leads from the front, taking keen interest in creation of new
            products. Regardsless to say that each one of products carry a
            guarantee of the best quality standards. 88 years of experience has
            made HRM a brand people can trust.
          </p>
          <img
            src={SPICE1}
            alt='spce1'
            className='w-[20rem] h-[20rem] rounded-full'
          />
        </div>
        <div className='flex flex-col md:flex-row justify-between border-[1px] gap-4 md:gap-0 md:px-16 border-yellow-500 py-4 items-center rounded-3xl'>
          <img
            src={SPICE2}
            alt='spce1'
            className='w-[20rem] h-[20rem] rounded-full'
          />
          <p className='text-justify px-4 md:w-[70%]'>
            From Kashmir to Kanyakumari, HRM Spices are symbol of good taste and
            fine cooking. Infact, taste and flavour have become the defination
            of HRM Spices. As a part of their expansion programme, HRM has
            established large fully automatic manufacturing plants at Delhi,
            Gurgaon (Haryana) 3 plants of spices, Papad and Export Unit, Nagaur
            (Rajasthan) 2 plants of Methi and spices and Sojat (Rajasthan).
            Branches have been set up at Ghaziabad (U.P.) Amritsar & Ludhiana
            (Punjab) Today HRM spices and blends are popular not just in India
            but also around the world and the products are being exported to the
            United States of America, Canada, United Kingdom, Europe, South East
            Asia, Japan, U.A.E. and Saudi Arabia. Company have own offices in
            London (U.K.) and a state of art manufacturing unit at Sharjah
            (U.A.E.)
          </p>
        </div>
        <div className='flex flex-col md:flex-row justify-between border-[1px] px-4 gap-4 md:gap-0  md:px-16 border-red-500 py-4 items-center rounded-3xl'>
          <p className='text-justify md:w-[70%]'>
            SPICESHRM blended spices are a class apart. Each blend has been
            specially formulated to impart the genuine taste to various Indian
            food preparations. Decades of research has gone into preparing these
            blends needed in specific and classical Indian dishes. Indian
            traditional cookery is free of preservatives and artificial flavour
            enhancers. HRM does not use these synthetics.
          </p>
          <img
            src={SPICE3}
            alt='spce1'
            className='w-[20rem] h-[20rem] rounded-full'
          />
        </div>
      </section>
    </AuroraBackground>
  );
};

export default About;
