import React from "react";
import JoinBuisness from "./JoinBuisness";
import Products from "./Products";
import { Banner } from "./Banner";
import About from "./About";
import Service from "./Service";
import Testimonial from "./Testimonial";
import ContactUs from "./Contact";

const Home = () => {
  return (
    <main>
      <Banner />
      <About />
      <Service />
      <Products />
      <Testimonial />
      <ContactUs />
      <JoinBuisness />
    </main>
  );
};

export default Home;
