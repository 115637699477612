import React from "react";
import { TypewriterEffectSmooth } from "../../ui/typewriter-effect";
import p1 from "../../assets/img/product1.png";
import p2 from "../../assets/img/product2.png";
import p3 from "../../assets/img/product3.png";
import p4 from "../../assets/img/product4.png";
import p5 from "../../assets/img/product5.jpeg";
import p6 from "../../assets/img/product6.jpeg";
import p7 from "../../assets/img/product7.jpeg";
import p8 from "../../assets/img/product8.jpeg";

const Products = () => {
  const words = [
    {
      text: "We",
    },
    {
      text: "have",
    },
    {
      text: "represents",
    },
    {
      text: "our",
    },
    {
      text: "some",
    },
    {
      text: "Products.",
      className: "text-blue-500 dark:text-blue-500",
    },
  ];
  const products = [
    {
      name: "Aata",
      img: p2,
    },
    {
      name: "Pulses",
      img: p3,
    },
    {
      name: "Spices",
      img: p4,
    },
    {
      name: "Besan",
      img: p5,
    },

    {
      name: "Corriander",
      img: p7,
    },
    {
      name: "Chili",
      img: p8,
    },
    {
      name: "Aata",
      img: './attanew.JPG',
    },
  ];
  return (
    <div className='p-8 bg-gray-100' id='Products'>
      <div className='text-center text-2xl md:text-4xl font-[800] text-gray-700 flex justify-center gap-2 items-center'>
        <span className='border-4 w-[3rem] md:w-[6rem] rounded-full border-yellow-500'></span>
        <h2>Products</h2>
        <span className='border-4 w-[3rem] md:w-[6rem]  rounded-full border-yellow-500'></span>
      </div>
      <div className='flex items-center justify-center'>
        <TypewriterEffectSmooth words={words} />
      </div>
      <div className='grid grid-cols-1 md:grid-cols-3  lg:grid-cols-3 gap-20 px-14 '>
        {products?.map(({ name, img }) => {
          return (
            <div className='flex flex-col shadow-2xl hover:translate-y-2 ease-in-out transition-transform rounded-xl'>
              <div className='h-[100%] '>
                <img
                  loading='lazy'
                  src={img}
                  alt={name}
                  className='h-full rounded-t-lg'
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Products;
