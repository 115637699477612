import React from "react";
import { motion } from "framer-motion";
import BannerImage from "../../assets/img/banner.svg";
import { BackgroundBeams } from "../../ui/background-beams";
import { Button } from "../../ui/moving-border";

export function Banner() {
  return (
    <main className=' flex-col md:flex-row flex justify-between  p-10 md:p-24' id="Home">
      <section className='md:w-[50%]'>
        <motion.a>
          <h2 className='font-[800] w-full text-[32px] md:text-[56px] text-green-600 '>
            <b className='text-red-600'>HRM</b> <br /> The Taste Of Purity.
            <br />
            <p className='text-[20px] font-[600] text-[#eab305]'>
              We Provide the Original Taste Of Indian Spices
            </p>
            <p className='text-gray-600 text-sm pt-10 text-justify md:pr-40'>
              Spices have a long and ancient history, especially in India, where
              they are a part of life and heritage. In every home & in every
              province across the country, different spices and blends are used
              to create different and distinctive tastes in dishes. Several
              decades ago, housewives used to grind their spices manually at
              home and make their own blends for use in their cooking. To make
              this process easier for the housewife, (HRM) visualised the
              concept of ready-to-use ground spices.
            </p>
          </h2>
          <div className='py-10 md:px-32 cursor-pointer'>
            <Button
              borderRadius='1.75rem'
              className='bg-white  dark:bg-slate-900 font-[800] text-lg text-[#eab305] dark:text-white border-neutral-200 dark:border-slate-800 cursor-pointer hover:bg-green-500 hover:text-white'
            >
              Contact Us
            </Button>
          </div>
        </motion.a>
      </section>
      <section className='md:w-[50%]'>
        <img src={BannerImage} alt='logo' className='w-full' />
      </section>
      {/* <BackgroundBeams /> */}
    </main>
  );
}
